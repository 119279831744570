import { memo } from 'react'
// @mui
import { useTheme } from '@mui/material/styles'
import { Box, BoxProps } from '@mui/material'
//
import BackgroundIllustration from './BackgroundIllustration'

// ----------------------------------------------------------------------

function SignupCompleteIllustration({ ...other }: BoxProps): JSX.Element {
  const theme = useTheme()

  const PRIMARY_LIGHT = theme.palette.primary.light

  const PRIMARY_MAIN = theme.palette.primary.main

  const PRIMARY_DARK = theme.palette.primary.dark

  return (
    <Box {...other}>
      <svg width="100%" height="100%" viewBox="0 0 480 360" xmlns="http://www.w3.org/2000/svg">
        <BackgroundIllustration />

        <image href="/assets/illustrations/characters/character_10.png" height="300" x="170" y="30" />

        <defs>
          <linearGradient
            id="paint0_linear_1_68"
            x1="267"
            x2="80.541"
            y1="272"
            y2="247.455"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={PRIMARY_MAIN} />
            <stop offset="1" stopColor={PRIMARY_DARK} />
          </linearGradient>

          <linearGradient
            id="paint1_linear_1_68"
            x1="80"
            x2="80"
            y1="155.117"
            y2="176.397"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={PRIMARY_LIGHT} />
            <stop offset="1" stopColor={PRIMARY_DARK} />
          </linearGradient>

          <linearGradient
            id="paint2_linear_1_68"
            x1="146.792"
            x2="146.792"
            y1="80"
            y2="97.537"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={PRIMARY_LIGHT} />
            <stop offset="1" stopColor={PRIMARY_DARK} />
          </linearGradient>

          <linearGradient id="paint3_linear_1_68" x1="228" x2="228" y1="217" y2="257" gradientUnits="userSpaceOnUse">
            <stop stopColor={PRIMARY_LIGHT} />
            <stop offset="1" stopColor={PRIMARY_DARK} />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  )
}

export default memo(SignupCompleteIllustration)
