'use client'

import { ReactNode, useContext, useState, useEffect } from 'react'

import { SWRConfig, mutate } from 'swr'

import { AuthContext } from '../auth/context/passwordless/auth-context'
import { fetcher, isUnauthenticatedEndpoint } from '../utils/axios'

export const swrOpts = {
  onErrorRetry: (err, key, config, revalidate, revalidateOpts) => {
    // don't retry on 403
    if (err.statusCode === 403) {
      return
    }
    setTimeout(() => revalidate(revalidateOpts), 5000)
  },
}

export const SWRProvider = ({ children }: { children: ReactNode }) => {
  const auth = useContext(AuthContext)
  const [skippedEndpoints, setSkippedEndpoints] = useState<string[]>([])

  useEffect(() => {
    if (auth?.authenticated && skippedEndpoints.length > 0) {
      // if user just became authenticated, hit all skipped endpoints by calling mutate
      mutate(
        (key) => {
          if (!Array.isArray(key) || key.length !== 2) {
            return false
          }
          return skippedEndpoints.includes(key[0])
        },
        undefined,
        { revalidate: true }
      ).then(() => {
        setSkippedEndpoints([])
      })
    }
  }, [auth?.authenticated, skippedEndpoints])

  const wrappedFetcher = async ([url, params]) => {
    const authenticated = auth?.authenticated || false

    // If the endpoint requires authentication and user is not authenticated, don't make the request
    if (!authenticated && !isUnauthenticatedEndpoint(url)) {
      setSkippedEndpoints([...skippedEndpoints, url])
      return null
    }

    return fetcher([url, params])
  }

  return (
    <SWRConfig
      value={{
        fetcher: wrappedFetcher,
        ...swrOpts,
      }}
    >
      {children}
    </SWRConfig>
  )
}
