// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/home',
}

// ----------------------------------------------------------------------

export const paths = {
  // AUTH
  auth: {
    // note we need the slash at the end bc we compare it with the value returned from usePathname()
    root: `${ROOTS.AUTH}/teams/login/`,
    microsoft: {
      login: `${ROOTS.AUTH}/microsoft/login/`,
    },
    teams: {
      login: `${ROOTS.AUTH}/teams/login/`,
    },
    slack: {
      login: `${ROOTS.AUTH}/slack/login/`,
      redirection: `${ROOTS.AUTH}/slack/redirection/`,
    },
    tab: {
      login: `${ROOTS.AUTH}/tab/login/`,
      consentPopUp: `${ROOTS.AUTH}/tab/consent-popup/`,
      success: `${ROOTS.AUTH}/tab/success/`,
      logout: `${ROOTS.AUTH}/tab/logout/`,
    },
  },
  // SIGNUP
  signup: {
    // note we need the slash at the end bc we compare it with the value returned from usePathname()
    root: `/signup`,
  },
  settingsTab: {
    root: '/settings-tab',
    profile: '/settings-tab/profile',
    connections: '/settings-tab/connections',
    personalProgramSettings: (programId: string): string => `/settings-tab/${programId}`,
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    unauthorized: `${ROOTS.DASHBOARD}/unauthorized/`,
  },
  home: '/home',
  audiences: {
    root: '/audiences',
  },
  programs: {
    root: '/programs',
    program: {
      manage: (id: string): string => `/programs/${id}/manage`,
      draft: (id: string): string => `/programs/${id}/draft`,
      analytics: (id: string): string => `/programs/${id}/manage/analytics`,
      users: (id: string): string => `/programs/${id}/manage/users`,
      matchHistory: (id: string): string => `/programs/${id}/manage/match-history`,
      configuration: (id: string): string => `/programs/${id}/manage/configuration`,
      settings: (id: string): string => `/programs/${id}/manage/settings`,
      audiences: (id: string): string => `/programs/${id}/manage/audiences`,
      personalSettings: (id: string): string => `/programs/${id}/manage/my-settings`,
      create: '/programs/create',
      createFromTemplate: (id: string): string => `/programs/create/${id}/template`,
    },
  },
  settings: {
    root: '/settings',
    profile: '/settings/profile',
    connections: '/settings/connections',
  },
  subscriptions: {
    root: '/subscriptions',
    account: {
      list: '/subscriptions/account/list',
      manage: (id: string): string => `/subscriptions/account/${id}/manage`,
      invoices: (id: string): string => `/subscriptions/account/${id}/manage/invoices`,
      admins: (id: string): string => `/subscriptions/account/${id}/admins`,
    },
    pricing: '/subscriptions/pricing',
    upgrade: {
      root: '/subscriptions/upgrade',
      account: (accountId: string): string => `/subscriptions/upgrade?accountId=${accountId}`,
    },
  },
}
