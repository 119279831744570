import { datadogLogs, Logger } from '@datadog/browser-logs'

class CustomLogger {
  private logger: Logger

  private productionDomain: string = 'app.coffeepals.com'

  constructor() {
    this.logger = datadogLogs.logger
  }

  private shouldLogToConsole(): boolean {
    return window.location.hostname !== this.productionDomain
  }

  private consoleLog(
    method: 'log' | 'info' | 'warn' | 'error',
    message: string,
    context?: object,
    error?: Error
  ): void {
    if (this.shouldLogToConsole()) {
      console[method](message, context, error)
    }
  }

  debug(message: string, messageContext?: object, error?: Error): void {
    this.consoleLog('log', message, messageContext, error)
    this.logger.debug(message, messageContext, error)
  }

  info(message: string, messageContext?: object, error?: Error): void {
    this.consoleLog('info', message, messageContext, error)
    this.logger.info(message, messageContext, error)
  }

  warn(message: string, messageContext?: object, error?: Error): void {
    this.consoleLog('warn', message, messageContext, error)
    this.logger.warn(message, messageContext, error)
  }

  error(message: string, messageContext?: object, error?: Error): void {
    this.consoleLog('error', message, messageContext, error)
    this.logger.error(message, messageContext, error)
  }
}

const customLogger = new CustomLogger()

export default customLogger
